<template>
  <div id="app">

    <template v-if="$route.name">

      <SEOStructuredData  v-if="$route.name && !new String($route.name).startsWith('natal-chart-widget') && !new String($route.name).startsWith('ncw-')" />

      <template v-if="this.screenWidth > 1600">
        <TopNavBar v-if="$route.name && !new String($route.name).startsWith('ncw-')" :nav-links="navLinks" background="#344E41" link-color="#FFFFFF" hoverBackground="#FF9F1C" />
      </template>
      <template v-else-if="this.screenWidth > 1200">
        <TopDesktopMenuBar :nav-links="navLinks" v-if="$route.name && !new String($route.name).startsWith('ncw-')" />
      </template>
      <template v-else>
        <TopMobileMenuBar :nav-links="navLinks" v-if="$route.name && !new String($route.name).startsWith('ncw-')" />
      </template>

    </template>
    
    <router-view />
    
  </div>
</template>

<script>

import SEOStructuredData from '@/views/SEOStructuredData'
import TopNavBar from '@/views/TopNavBar'
import TopMobileMenuBar from './views/TopMobileMenuBar.vue';
import TopDesktopMenuBar from './views/TopDesktopMenuBar.vue';

export default {
  components: {
    SEOStructuredData,
    TopNavBar,
    TopMobileMenuBar,
    TopDesktopMenuBar
  },
  data: () => ({
    screenWidth: window.innerWidth,
    navLinks: [
    {
      text: 'Natal Chart',
      path: '/natal-chart',
      icon: 'fas fa-file-alt fa-lg',
      fawIcon: ['fas', 'file-lines'],
    },
    {
      text: 'Planetary Positions',
		  path: '/planetary-positions',
		  icon: 'fa fa-globe-americas fa-lg',
      fawIcon: ['fas', 'earth-americas'],
    },
    {
      text: 'Transits',
		  path: '/transits',
		  icon: 'fas fa-spinner fa-lg',
      fawIcon: ['fas', 'arrows-rotate'],
    },
    {
      text: 'Panchanga',
		  path: '/panchanga',
		  icon: 'far fa-calendar-alt fa-lg',
      fawIcon: ['far', 'calendar-alt']
    },
    {
      text: 'Jamakkol Horary',
		  path: '/jamakkol',
		  icon: 'fa fa-hour-glass fa-lg',
      fawIcon: ['far', 'hourglass'],
    },
    {
       text: 'Widgets',
		   path: '/widgets',
		   icon: 'fas fa-link fa-lg',
       fawIcon: ['fas', 'link']
     },
    // {
    //   text: 'Blog',
		//   path: '/blog',
		//   icon: 'fab fa-blogger fa-lg',
    //   fawIcon: ['fab', 'blogger']
    // },
    {
      text: 'FAQ',
		  path: '/faq',
		  icon: 'fas fa-question-circle fa-lg',
      fawIcon: ['fas', 'circle-question']
    },
    {
      text: 'Contact',
		  path: '/contact',
		  icon: 'fas fa-envelope fa-lg',
      fawIcon: ['fas', 'envelope']
    }
    ]
  }),

	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
	},	
	beforeUnmount() { 
		window.removeEventListener('resize', this.onResize); 
	},
  methods: {
    onResize() {
      this.screenWidth = window.innerWidth;
    }
  }  
}
</script>

<style lang="scss">


body {
  margin: 0;
  background: #FBFBFF;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
