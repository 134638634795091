<template>
    <div class="tarot-results-wrapper">
      <!--
        <div class="tarot-results-content" id="H1-00">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-00.png" alt="The Fool" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : The Fool</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Embrace your inner child and leap into the unknown with a heart full of optimism. Trust that new beginnings bring valuable lessons.</li>
                    <li><b>Identity</b>: You are free to reinvent yourself. Let go of past limitations and step into a new version of you, unburdened and fearless.</li>
                    <li><b>Personality</b>: Cultivate a playful spirit. Your curiosity and sense of adventure are your greatest assets. Approach challenges with a fresh perspective.</li>
                    <li><b>Attitude</b>: Adopt a mindset of possibility. Every path you take, no matter how uncertain, holds the potential for discovery.</li>
                    <li><b>Approach to Life</b>: Live spontaneously and with joy. Let go of overthinking and allow the flow of life to guide you to new horizons.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-01">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-01.png" alt="The Magician" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : The Magician</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Recognize your innate ability to manifest your desires. You possess all the tools needed to create the life you envision.</li>
                    <li><b>Identity</b>: Stand in your personal power. You are a creator, capable of shaping your world with skill and focus.</li>
                    <li><b>Personality</b>: Your resourcefulness and determination shine through. Be confident in your ability to overcome obstacles.</li>
                    <li><b>Attitude</b>: Believe that anything is possible. Harness your energy and take deliberate action toward your goals.</li>
                    <li><b>Approach to Life</b>: Approach challenges as opportunities to create solutions. Trust your abilities to transform ideas into reality.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-02">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-02.png" alt="The High Priestess" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : The High Priestess</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Honor your intuition. Trust the quiet, inner voice that speaks truths beyond reason and logic.</li>
                    <li><b>Identity</b>: Embrace the deep wisdom within. You are connected to an ancient and infinite source of knowledge.</li>
                    <li><b>Personality</b>: Cultivate mystery and subtlety. Your power lies in your ability to observe and understand the world without needing to control it.</li>
                    <li><b>Attitude</b>:  Practice patience and trust. Everything you need will be revealed to you in its own time.</li>
                    <li><b>Approach to Life</b>: Let intuition guide your decisions. Trust that the universe will lead you to exactly where you need to be.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-03">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-03.png" alt="The Empress" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : The Empress</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Nurture yourself and the world around you. Recognize your natural ability to create, protect, and provide.</li>
                    <li><b>Identity</b>: Embody the essence of abundance. You are a vessel of creativity and fertility, capable of birthing new ideas and realities.</li>
                    <li><b>Personality</b>: Your compassion and warmth make you a magnet for others. Share your nurturing energy to foster growth and harmony.</li>
                    <li><b>Attitude</b>:  Adopt a mindset of abundance, knowing there is always enough for you to share. Your love and care enrich the world.</li>
                    <li><b>Approach to Life</b>: Approach life as a garden to be tended with patience, love, and a deep respect for the cycles of nature.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-04">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-04.png" alt="The Emperor" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : The Emperor</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Stand firm in your values and boundaries. You are a leader, able to shape your destiny through discipline and strength.</li>
                    <li><b>Identity</b>: Build your foundation on solid principles. You are capable of creating order from chaos, and stability from uncertainty.</li>
                    <li><b>Personality</b>: Your authority and decisiveness inspire confidence in others. Lead with integrity and strength.</li>
                    <li><b>Attitude</b>:  Approach life with a strategic mindset. Every challenge is an opportunity to assert your vision and take control.</li>
                    <li><b>Approach to Life</b>: Face life with courage and conviction. You are the architect of your future, and your actions shape your reality.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-05">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-05.png" alt="The Hierophant" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : The Hierophant</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Seek wisdom in tradition and spiritual teachings. There is deep value in understanding the systems and rituals that guide your life.</li>
                    <li><b>Identity</b>: Align yourself with higher knowledge. You are a student of life, and your journey is one of growth and learning.</li>
                    <li><b>Personality</b>: You exude stability and guidance. Others look to you for spiritual insight and support.</li>
                    <li><b>Attitude</b>: Embrace structured growth. Let ancient wisdom inform your decisions while remaining open to new perspectives.</li>
                    <li><b>Approach to Life</b>: Approach life with reverence for tradition, while being open to adapting teachings to your unique path.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-06">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-06.png" alt="The Lovers" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : The Lovers</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Honor your connections, both with yourself and others. Love starts from within and radiates outward.</li>
                    <li><b>Identity</b>: Understand the power of choice. Every decision you make aligns you with your values, bringing you closer to your authentic self.</li>
                    <li><b>Personality</b>: You attract harmonious relationships. Your ability to unite differing energies creates balance and love.</li>
                    <li><b>Attitude</b>: Cultivate mutual respect and understanding. Love is both a path of harmony and a choice of commitment.</li>
                    <li><b>Approach to Life</b>: Approach life with an open heart, knowing that love is the ultimate guide to making meaningful choices.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-07">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-07.png" alt="The Chariot" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : The Chariot</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Harness your willpower. You are driven to succeed, and you can overcome any obstacle through determination and focus.</li>
                    <li><b>Identity</b>: You are a force of nature. By maintaining control and direction, you are unstoppable in pursuit of your goals.</li>
                    <li><b>Personality</b>: Your dynamic energy is magnetic. You inspire others with your confidence and the clarity of your vision.</li>
                    <li><b>Attitude</b>: Adopt a mindset of unwavering perseverance. Victory is achieved through sheer will and steady progress.</li>
                    <li><b>Approach to Life</b>: Approach life like a race. With focus, control, and momentum, no challenge is too great.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-08">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-08.png" alt="Strength" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : Strength</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Embrace your inner power. True strength lies not in force, but in patience, compassion, and resilience.</li>
                    <li><b>Identity</b>: You are capable of taming your fears and doubts. Your courage comes from within, and it can transform any challenge.</li>
                    <li><b>Personality</b>: Your calm determination and emotional balance inspire respect and admiration. You are a beacon of inner fortitude.</li>
                    <li><b>Attitude</b>: Approach life with grace under pressure. Strength is not about dominating, but about rising above with poise and compassion.</li>
                    <li><b>Approach to Life</b>: Embrace every challenge with a gentle yet unwavering spirit. Conquer obstacles with patience and inner peace.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-09">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-09.png" alt="The Hermit" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : The Hermit</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Seek wisdom within. You have the power to illuminate your own path through introspection and solitude.</li>
                    <li><b>Identity</b>: Embrace the journey of self-discovery. Your inner light guides you through the darkness.</li>
                    <li><b>Personality</b>: Your quiet nature allows you to see deeply into the hearts of others. You exude wisdom and clarity.</li>
                    <li><b>Attitude</b>: Approach life with introspective curiosity. Wisdom is found in stillness and self-reflection.</li>
                    <li><b>Approach to Life</b>: Take moments of solitude to nourish your soul. In quiet contemplation, the answers to life’s questions will reveal themselves.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-10">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-10.png" alt="Wheel of Fortune" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : Wheel of Fortune</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Accept the ebb and flow of life. Change is inevitable, but trust that every turn of the wheel brings new opportunities.</li>
                    <li><b>Identity</b>: Recognize that life is cyclical. You are not defined by your circumstances; you are a part of a larger, ever-changing flow.</li>
                    <li><b>Personality</b>: Your adaptability makes you resilient in the face of uncertainty. Embrace the dance of fate and fortune.</li>
                    <li><b>Attitude</b>: Embrace the unpredictability of life. Every change is a chance to grow, evolve, and experience something new.</li>
                    <li><b>Approach to Life</b>: Life is a wheel in constant motion. Let go of the need for control and trust that every twist brings growth.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-11">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-11.png" alt="Justice" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : Justice</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Be fair with yourself. Make decisions that honor your values and create balance in your life.</li>
                    <li><b>Identity</b>: You are a being of integrity. Stand firm in your truth and take responsibility for your actions.</li>
                    <li><b>Personality</b>: Your sense of fairness and impartiality makes you a trusted advisor. Others turn to you for clarity and guidance.</li>
                    <li><b>Attitude</b>: Approach life with a balanced mindset. Seek fairness, truth, and accountability in all your interactions.</li>
                    <li><b>Approach to Life</b>: Life is a balance of give and take. Approach every decision with fairness, knowing that what you put out into the world returns to you.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-12">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-12.png" alt="The Hanged Man" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : The Hanged Man</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Release the need to control. Sometimes, surrendering is the most powerful thing you can do.</li>
                    <li><b>Identity</b>: View yourself from a new perspective. What you once thought of as obstacles are opportunities to learn and grow.</li>
                    <li><b>Personality</b>: Your ability to pause, reflect, and see life through fresh eyes is your superpower. You bring new insights to old situations.</li>
                    <li><b>Attitude</b>: Adopt a mindset of patience and acceptance. Sometimes, waiting and letting go brings clarity and peace.</li>
                    <li><b>Approach to Life</b>: Life is not a race. Sometimes, you must step back and surrender to the flow, knowing that in stillness, wisdom is found.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-13">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-13.png" alt="Death" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : Death</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Release what no longer serves you. Transformation is essential for growth, and embracing change will lead you to reinvent yourself.</li>
                    <li><b>Identity</b>: You are constantly evolving. Allow old versions of yourself to fall away to make room for a more powerful you.</li>
                    <li><b>Personality</b>: You are a symbol of transformation. Others see in you the ability to rise from the ashes, renewed and powerful.</li>
                    <li><b>Attitude</b>: Embrace the cycles of life. Trust that every ending is a prelude to a new beginning.</li>
                    <li><b>Approach to Life</b>: Life is a process of constant transformation. Release fear and embrace the renewal that change brings.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-14">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-14.png" alt="Temperance" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : Temperance</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Find balance within. Integrate the opposing forces within you to create a harmonious and centered self.</li>
                    <li><b>Identity</b>: You are a being of balance and moderation. Your ability to blend different energies creates inner peace and harmony.</li>
                    <li><b>Personality</b>: Your calm demeanor and ability to harmonize any situation are your gifts. Others are drawn to your peaceful nature.</li>
                    <li><b>Attitude</b>: Approach life with patience and moderation. Avoid extremes and seek balance in every aspect of your life.</li>
                    <li><b>Approach to Life</b>: Life is a delicate dance of opposites. Seek harmony and integration in every choice you make.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-15">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-15.png" alt="The Devil" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : The Devil</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Recognize the power of liberation. Break free from the chains that hold you back, and reclaim your personal power.</li>
                    <li><b>Identity</b>: You are more than your fears or addictions. Unshackle yourself from negative patterns and step into your true freedom.</li>
                    <li><b>Personality</b>: You exude the strength to confront your shadows. Your awareness of your limits makes you powerful in overcoming them.</li>
                    <li><b>Attitude</b>: Approach life with awareness. Identify the forces that restrict you and take active steps to free yourself.</li>
                    <li><b>Approach to Life</b>: Life is about breaking free from limitations. Confront your fears, release toxic patterns, and embrace your personal freedom.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-16">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-16.png" alt="The Tower" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : The Tower</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Embrace disruption as a force for growth. Sometimes, things must fall apart to create the space for something better.</li>
                    <li><b>Identity</b>: You are resilient, and able to rebuild stronger after destruction. Your foundation may be shaken, but your strength will carry you.</li>
                    <li><b>Personality</b>: Your ability to handle unexpected changes with grace and strength inspires those around you.</li>
                    <li><b>Attitude</b>: View crises as opportunities to rebuild. Growth often comes from upheaval, and it is in these moments you discover your true resilience.</li>
                    <li><b>Approach to Life</b>: When life shakes you, rise from the rubble with strength. Transformation is often messy but always necessary.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-17">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-17.png" alt="The Star" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : The Star</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Reconnect with hope and inspiration. You are a shining beacon of possibilities, radiating light and peace.</li>
                    <li><b>Identity</b>: Embrace your true nature. You are a unique and beautiful soul, destined to shine bright in the universe.</li>
                    <li><b>Personality</b>: Your optimism and calm provide healing and hope to those around you. You uplift others with your presence.</li>
                    <li><b>Attitude</b>: Hold onto hope, knowing that even in darkness, your light will guide you forward.</li>
                    <li><b>Approach to Life</b>: Life is filled with infinite possibilities. Approach each day with a renewed sense of hope, knowing that the universe supports you.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-18">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-18.png" alt="The Moon" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : The Moon</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Embrace the mysteries within you. Trust that you are deeply connected to your subconscious, where intuition and wisdom reside.</li>
                    <li><b>Identity</b>: Honor your emotional depth. You possess an intuitive nature that guides you through life's uncertainties.</li>
                    <li><b>Personality</b>: Your ability to sense the unseen is a gift. Others find comfort in your understanding and empathic presence.</li>
                    <li><b>Attitude</b>: Trust your instincts, even when things are unclear. The truth may be hidden, but it will always reveal itself in time.</li>
                    <li><b>Approach to Life</b>: Life is full of hidden truths. Approach it with curiosity, trusting that intuition will guide you toward clarity.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-19">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-19.png" alt="The Sun" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : The Sun</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Celebrate your light. You are vibrant, full of life, and capable of achieving greatness with your boundless energy.</li>
                    <li><b>Identity</b>: Recognize your inner radiance. You are deserving of joy, abundance, and success.</li>
                    <li><b>Personality</b>: Your positivity and warmth draw others to you. You have a magnetic presence that brings clarity and joy to any situation.</li>
                    <li><b>Attitude</b>: Approach life with optimism. Every day is an opportunity to shine brightly and share your light with the world.</li>
                    <li><b>Approach to Life</b>: Life is a celebration. Embrace each moment with joy, knowing you have the power to create happiness wherever you go.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-20">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-20.png" alt="Judgment" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : Judgment</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Embrace transformation and rebirth. Recognize the call to evolve and shed old habits, making space for a new, higher version of yourself.</li>
                    <li><b>Identity</b>: You are ready for change. Rebirth yourself by releasing old patterns and stepping into a new life.</li>
                    <li><b>Personality</b>: Your clarity and sense of purpose inspire others. You know when it’s time to let go and move forward.</li>
                    <li><b>Attitude</b>: Accept change as part of your evolution. Life is calling you to rise to your highest potential.</li>
                    <li><b>Approach to Life</b>: Life is about second chances. Embrace every opportunity to transform and align yourself with your higher calling.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H1-21">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-21.png" alt="The World" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-1 : The World</h3>
                </u>
                <ul>
                    <li><b>Self</b>: Recognize your completeness. Reach the point of balance and integration, where all parts of you are aligned.</li>
                    <li><b>Identity</b>: You are whole, having embraced every aspect of yourself. Celebrate your journey and all that you’ve learned.</li>
                    <li><b>Personality</b>: Your sense of wholeness is magnetic. Exude confidence and harmony, completing the cycles of life with grace.</li>
                    <li><b>Attitude</b>: Approach life with gratitude. Be in harmony with the universe, and all your efforts will lead to a moment of fulfillment.</li>
                    <li><b>Approach to Life</b>: Life is a cycle of constant growth. Celebrate your achievements, knowing that you are ready for the next chapter in your journey.</li>
                </ul>
            </div>
        </div>
        -->
        <!--
        <div class="tarot-results-content" id="H2-00">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-00.png" alt="The Fool" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : The Fool</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Be fearless and open to new opportunities. Wealth often begins with a leap of faith. Take risks, but make sure you are prepared to learn along the way.</li>
                    <li><b>Income</b>: Embrace new ventures and innovative ideas. Fresh beginnings could bring unexpected income streams. Stay curious and embrace the unknown.</li>
                    <li><b>Family</b>: Infuse your family relationships with joy and lightheartedness. Your open heart and adventurous spirit can bring harmony to your loved ones.</li>
                    <li><b>Material Possessions</b>: Don’t get attached to material goods. Possessions are fleeting; enjoy the journey and the experiences you collect, not just the things.</li>
                    <li><b>Speech</b>: Speak with authenticity and innocence. Your words can inspire and spark others to step into the unknown with optimism.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-01">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-01.png" alt="The Magician" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : The Magician</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Harness your personal power and resources to manifest abundance. You have the skills to turn opportunities into tangible wealth.</li>
                    <li><b>Income</b>: Focus on your talents and potential. Align your skills with action and intention to manifest steady streams of income.</li>
                    <li><b>Family</b>: Bring communication and connection to your family. Use your cleverness to solve family challenges and lead by example.</li>
                    <li><b>Material Possessions</b>: Your resourcefulness will attract the material things you need. Use your ability to manifest wisely and avoid excess.</li>
                    <li><b>Speech</b>: Speak with confidence and clarity. Your words have the power to create and transform your reality, so choose them with care.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-02">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-02.png" alt="The High Priestess" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : The High Priestess</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Trust your intuition when it comes to financial matters. Sometimes, the greatest wealth comes from unseen opportunities and quiet insights.</li>
                    <li><b>Income</b>: Deepen your knowledge and understanding. Income flows where wisdom is applied, so invest in learning and trusting your inner guide.</li>
                    <li><b>Family</b>: Create a serene and nurturing environment for your family. Embrace the role of the calm, wise figure that provides comfort and insight.</li>
                    <li><b>Material Possessions</b>: Besides material wealth, focus on the unseen, spiritual wealth that will bring lasting satisfaction.</li>
                    <li><b>Speech</b>: Speak with quiet wisdom. Let your words flow gracefully, offering insight and understanding to those around you.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-03">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-03.png" alt="The Empress" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : The Empress</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Nurture your wealth with care and attention. Cultivate abundance, and watch it grow in all areas of your life.</li>
                    <li><b>Income</b>: Create opportunities for sustainable income through nurturing ventures. Invest in what grows and flourishes over time.</li>
                    <li><b>Family</b>: Be a pillar of support and care within your family. Lead with love, and create an environment where everyone feels nourished and valued.</li>
                    <li><b>Material Possessions</b>: Your abundance will manifest as material possessions, but remember to cherish the beauty in simplicity. Nurture what you have with gratitude.</li>
                    <li><b>Speech</b>: Use your words to comfort and nurture others. Speak with warmth and kindness, creating bonds that last.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-04">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-04.png" alt="The Emperor" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : The Emperor</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Establish strong foundations for your wealth. Take control of your financial destiny through discipline, authority, and strategic planning.</li>
                    <li><b>Income</b>: Build a structure for sustained income. Take a leadership role in managing your earnings, and ensure your financial future is secure.</li>
                    <li><b>Family</b>: Be the strong, protective figure your family needs. Offer structure and support while encouraging autonomy and growth in your loved ones.</li>
                    <li><b>Material Possessions</b>: Accumulate material wealth through hard work and consistency. Value what you have earned and build a stable, prosperous life.</li>
                    <li><b>Speech</b>: Speak with authority and decisiveness. Your words can command respect and establish your leadership in any situation.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-05">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-05.png" alt="The Hierophant" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : The Hierophant</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Cultivate wealth through tradition, knowledge, and established systems. Rely on wisdom and established structures to build your financial future.</li>
                    <li><b>Income</b>: Seek income through institutions or formalized knowledge. The right teacher or mentor may lead you to opportunities for growth.</li>
                    <li><b>Family</b>: Maintain strong family traditions and values. Uphold family bonds through shared beliefs and support each member’s growth.</li>
                    <li><b>Material Possessions</b>: Accumulate wealth in ways that align with your core values. Material possessions should serve your greater purpose.</li>
                    <li><b>Speech</b>: Speak with reverence and respect for tradition. Share wisdom that elevates and guides others toward a higher purpose.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-06">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-06.png" alt="The Lovers" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : The Lovers</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Wealth is found in choices that align with your values. Make decisions based on your heart and trust that prosperity will follow.</li>
                    <li><b>Income</b>: Align your income with your passions and desires. Work with love, and the rewards will be bountiful.</li>
                    <li><b>Family</b>: Strengthen family bonds through love and mutual respect. Encourage open, heartfelt communication to foster harmony.</li>
                    <li><b>Material Possessions</b>: Enjoy material wealth in partnership with others. Sharing possessions brings deeper fulfillment.</li>
                    <li><b>Speech</b>: Speak with love and compassion. Your words should promote unity, understanding, and deep connections.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-07">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-07.png" alt="The Chariot" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : The Chariot</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Drive forward with determination and focus. Wealth is achieved by taking charge and maintaining a clear direction.</li>
                    <li><b>Income</b>: Use your willpower and focus to push through obstacles. You have the ability to harness opportunities for financial gain.</li>
                    <li><b>Family</b>: Lead your family with strength and purpose. Encourage each member to take action and move toward their individual goals.</li>
                    <li><b>Material Possessions</b>: Command your material wealth with discipline and control. Let your drive for success translate into the resources you need.</li>
                    <li><b>Speech</b>: Speak with confidence and clarity. Your words should inspire others to take action and move forward with purpose.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-08">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-08.png" alt="Strength" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : Strength</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Build wealth through patience and inner strength. The ability to endure challenges with grace will attract the abundance you seek.</li>
                    <li><b>Income</b>: Your income will increase through resilience and quiet power. Cultivate steady progress and face financial challenges with courage.</li>
                    <li><b>Family</b>: Be the steady rock in your family. Your strength and compassion can guide your loved ones through any difficulty.</li>
                    <li><b>Material Possessions</b>: Material wealth will come when you combine patience with inner strength. You can create stability and abundance over time.</li>
                    <li><b>Speech</b>: Speak with kindness and authority. Your words should convey both power and compassion, inspiring those around you.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-09">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-09.png" alt="The Hermit" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : The Hermit</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Seek wealth through introspection and solitude. Sometimes, the greatest wealth comes from within and a deep understanding of your true path.</li>
                    <li><b>Income</b>: Look for opportunities that align with your wisdom. Income may come through teaching, writing, or quiet work behind the scenes.</li>
                    <li><b>Family</b>: Offer wisdom and counsel to your family when they need it. Embrace solitude to recharge and give better support to your loved ones.</li>
                    <li><b>Material Possessions</b>: Simplify your life and focus on what truly matters. Besides material wealth, seek spiritual wealth and inner peace.</li>
                    <li><b>Speech</b>: Speak with quiet wisdom. Your words should be few but profound, offering deep insights when necessary.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-10">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-10.png" alt="Wheel of Fortune" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : Wheel of Fortune</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Embrace change and cycles in your financial life. Sometimes, wealth comes when you are open to the ebb and flow of fortune.</li>
                    <li><b>Income</b>: Know that income is cyclical. Don’t resist the changes—adjust and flow with the opportunities that present themselves.</li>
                    <li><b>Family</b>: Family dynamics may shift, but these changes bring growth. Be adaptable and allow the natural flow of life to guide relationships.</li>
                    <li><b>Material Possessions</b>: Material wealth will come and go. Focus on adaptability and accepting the changes in your life, allowing possessions to ebb naturally.</li>
                    <li><b>Speech</b>: Speak with an understanding that everything is in motion. Your words should reflect a balanced perspective on the ups and downs of life.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-11">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-11.png" alt="Justice" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : Justice</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Seek wealth through fairness and balance. Financial success will come when you align your actions with integrity and justice.</li>
                    <li><b>Income</b>: Ensure your income is earned through fair practices. Take the necessary steps to ensure your financial dealings are equitable and just.</li>
                    <li><b>Family</b>: Foster fairness and balance within your family. Encourage open communication and fair distribution of responsibilities.</li>
                    <li><b>Material Possessions</b>: Your material possessions will reflect your sense of balance and fairness. Avoid excess and focus on what is truly deserved.</li>
                    <li><b>Speech</b>: Speak with clarity and fairness. Your words should promote balance, truth, and equality in every conversation.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-12">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-12.png" alt="The Hanged Man" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : The Hanged Man</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Sometimes, wealth comes from letting go and seeing things from a new perspective. Surrender to change and trust that the universe will provide.</li>
                    <li><b>Income</b>: Let go of old ways of earning. A fresh perspective may reveal income opportunities that you hadn’t considered before.</li>
                    <li><b>Family</b>: Let go of control within your family. Sometimes, surrendering to the flow allows others to grow and flourish on their own.</li>
                    <li><b>Material Possessions</b>: Don’t hold on too tightly to material goods. Relax from the attachments to possessions, and you’ll find a greater sense of peace and abundance.</li>
                    <li><b>Speech</b>: Speak with humility and insight. Your words should reflect an understanding of the need to let go and embrace new perspectives.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-13">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-13.png" alt="Death" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : Death</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Welcome the end of old financial patterns. Let go of what no longer serves you, and make room for new opportunities that will lead to greater wealth.</li>
                    <li><b>Income</b>: If old income sources fade away, create space for new ones by embracing transformation, and being open to reinvention.</li>
                    <li><b>Family</b>: Family dynamics may undergo deep transformations. Be open to change and allow growth within your family relationships.</li>
                    <li><b>Material Possessions</b>: Let go of unnecessary possessions. Declutter and create space for what truly adds value to your life.</li>
                    <li><b>Speech</b>: Speak of endings and beginnings with grace. Your words should inspire others to embrace transformation and let go of the old.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-14">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-14.png" alt="Temperance" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : Temperance</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Cultivate wealth through balance and moderation. Be patient and careful with your financial decisions, and wealth will grow steadily over time.</li>
                    <li><b>Income</b>: Balance your income sources to create steady growth. Be mindful of overextending yourself, and focus on gradual progress.</li>
                    <li><b>Family</b>: Foster harmony and cooperation in your family. Help each member find their balance and offer support where needed.</li>
                    <li><b>Material Possessions</b>: Maintain balance in your material possessions. Avoid excess and focus on quality and usefulness.</li>
                    <li><b>Speech</b>: Speak with calm and moderation. Your words should create peace and balance, encouraging harmony in conversations.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-15">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-15.png" alt="The Devil" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : The Devil</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Break free from unhealthy attachments to wealth. Prosperity comes when you are not enslaved by the pursuit of material gain.</li>
                    <li><b>Income</b>: Examine where you may be trapped in unhealthy income sources. Focus on breaking free from these patterns to build a healthier financial future.</li>
                    <li><b>Family</b>: Address toxic patterns within your family. Liberate yourself and others from unhealthy dynamics.</li>
                    <li><b>Material Possessions</b>: Do not become enslaved by possessions. Enjoy what you have and examine what else truly adds value to your life.</li>
                    <li><b>Speech</b>: Speak with honesty about your desires and attachments. Challenge others to break free from their own limiting beliefs.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-16">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-16.png" alt="The Tower" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : The Tower</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Be prepared for sudden changes in your financial landscape. Though it may feel tumultuous, it can lead to the clearing of the old and the birth of new opportunities.</li>
                    <li><b>Income</b>: Be ready for sudden shifts in income. While unsettling, these changes may offer the freedom to rebuild a more solid foundation.</li>
                    <li><b>Family</b>: Family foundations may shake, but this disruption can lead to greater understanding and unity. Embrace the rebuilding process.</li>
                    <li><b>Material Possessions</b>: Material possessions may be disrupted, but this clears the way for new opportunities. Focus on what is essential, and let go of the excess.</li>
                    <li><b>Speech</b>: Speak truthfully, even when the truth may be uncomfortable. Your words can bring necessary change, even if it shakes the foundations.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-17">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-17.png" alt="The Star" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : The Star</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Hope and optimism bring wealth. Trust that the universe will provide, and keep your faith in your ability to achieve financial success.</li>
                    <li><b>Income</b>: Nurture new sources of income that align with your hopes and dreams. The future is bright if you continue to believe in yourself.</li>
                    <li><b>Family</b>: Inspire your family with hope and optimism. Help them see the potential in themselves and create an environment of healing and growth.</li>
                    <li><b>Material Possessions</b>: Material possessions will come naturally when you remain optimistic. Focus on the beauty in the small things and trust in abundance.</li>
                    <li><b>Speech</b>: Speak with hope and encouragement. Your words should inspire others to reach for their dreams and trust that they can achieve greatness.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-18">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-18.png" alt="The Moon" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : The Moon</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Trust your intuition when making financial decisions. Wealth may come from hidden sources, so be open to what lies beneath the surface.</li>
                    <li><b>Income</b>: Keep an open mind when it comes to income sources. Some opportunities may appear confusing at first but will become clear in time.</li>
                    <li><b>Family</b>: Navigate family dynamics with sensitivity. Emotional clarity and intuition can help you understand underlying issues.</li>
                    <li><b>Material Possessions</b>: If your material wealth is elusive focus more on spiritual growth and emotional security, and the material world will follow.</li>
                    <li><b>Speech</b>: Speak with empathy and understanding. Your words should reflect your deeper intuition and help others see beyond the illusion.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-19">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-19.png" alt="The Sun" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : The Sun</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Prosperity shines brightly when you embrace positivity and abundance. Your financial future is illuminated by your optimism and joy.</li>
                    <li><b>Income</b>: Income will grow as you align with your true purpose. The more you radiate positivity, the more opportunities will come.</li>
                    <li><b>Family</b>: Be the light of positivity and warmth within your family. Your joy will create a supportive, happy home environment.</li>
                    <li><b>Material Possessions</b>: Enjoy the material wealth that comes with your success, but remember to share your abundance with others.</li>
                    <li><b>Speech</b>: Speak with confidence and clarity. Your words will inspire and uplift, bringing light to those around you.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-20">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-20.png" alt="Judgment" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : Judgment</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Wealth comes through a deep reckoning with your past financial choices. Take responsibility, and the universe will reward your transformation.</li>
                    <li><b>Income</b>: Your income will transform when you evaluate your old financial habits and make a conscious decision to evolve.</li>
                    <li><b>Family</b>: Encourage your family to evaluate their choices and make positive changes. This renewal will bring greater unity and understanding.</li>
                    <li><b>Material Possessions</b>: Let go of the old and outdated. Clear your space for what truly serves you, and the universe will fill it with what you need.</li>
                    <li><b>Speech</b>: Speak with clarity and purpose. Your words can guide others to make important decisions and embrace their own transformation.</li>
                </ul>
            </div>
        </div>
        <div class="tarot-results-content" id="H2-21">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-21.png" alt="The World" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-2 : The World</h3>
                </u>
                <ul>
                    <li><b>Wealth</b>: Completion brings wealth. Your efforts have borne fruit, and now is the time to reap the rewards of your hard work and dedication.</li>
                    <li><b>Income</b>: Your income reflects the culmination of your hard work. Celebrate your success, knowing that you are now in a place of abundance.</li>
                    <li><b>Family</b>: Bring your family together to celebrate the harmony you’ve built. There’s a sense of wholeness and completion that can now guide your relationships.</li>
                    <li><b>Material Possessions</b>: Enjoy the material rewards of your hard work, but know that the true riches lie in the journey itself.</li>
                    <li><b>Speech</b>: Speak with a sense of completion. Your words should reflect the harmony and balance you have achieved in all areas of your life.</li>
                </ul>
            </div>
        </div>
        -->
        <div class="tarot-results-content" id="H3-00">
            <div class="house-tarot-image">
                <img width="280" src="../assets/tarot/tarot-major-arcana-00.png" alt="The Fool" />
            </div>
            <div class="house-tarot-content">
                <u>
                    <h3>House-3 : The Fool</h3>
                </u>
                <ul>
                    <li><b>Courage</b>: The Fool encourages you to step into the unknown with a sense of adventure and optimism. Trust your instincts and take bold steps forward and embrace the freedom that comes with not knowing the full path ahead.</li>
                    <li><b>Communication</b>: When you communicate, let your words be guided by your inner curiosity, unafraid of what others may think. Speak with a lightness that inspires others to listen with open hearts.</li>
                    <li><b>Short Travel</b>: Your journeys will be full of excitement and new perspectives. Whether you're taking a short trip or an impulsive detour, trust that every experience will enrich your soul.</li>
                    <li><b>Younger Siblings</b>: Encourage your younger siblings to take risks and embrace their own adventures. Be a role model of joy and spontaneity in their lives.</li>
                    <li><b>Effort</b>: Let the Fool remind you to start fresh, trusting your own unique path. Each effort you make is an opportunity to create something new and uncharted.</li>
                </ul>
            </div>
        </div>

        <div class="natal-chart-button-area">
            <a class="natal-chart-link" @click="$router.push('tarot-house-spread')">&nbsp;Go Back&nbsp;</a>
        </div>
    </div>
</template>

<style scoped>

body {
  font-family: Arial, Helvetica, sans-serif;
}

h3 {
  margin: 25px 0 0;
}

.natal-chart-link {
  border-radius: 5px;
  background-color: #087F8C;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  padding: 12px;
  text-decoration: none;
}

li {
  text-align: left;
  padding: 5px;
}

p {
  color: #344E41;
  text-indent: 10px;
  text-align: left;
  padding: 10px;
}

.tarot-results-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;  
  border-radius: 10px;
  justify-content: center;
  padding-top: 50px;
}

.tarot-results-content {
  border: 1px solid;
  border-color: #a0a0a0;
  align-content: center;
  background: #EDF6F9;
  border-radius: 10px;
  margin-top: 10px;

  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.natal-chart-button-area {
  width: 50%;
  border: 0px solid;
  padding: 30px;
  cursor: pointer;
}

.house-tarot-image {
  border: 0px solid;
  width: 35%;
  padding: 5px;
}
.house-tarot-content {
  width: 65%;
  padding: 5px;
}

@media screen and (max-width: 1605px) {

  p {
    color: #344E41;
    text-indent: 10px;
    text-align: left;
    padding: 10px;
  }

  h3 {
    margin: 25px 0 0;
  }

  li {
    text-align: left;
    padding: 3px;
    list-style-position: outside;
  }  

  .tarot-results-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;  
    border-radius: 10px;
    padding-top: 10px;
  }
 
  .tarot-results-content {
    border: 1px solid;
    border-color: #a0a0a0;
    align-content: center;
    background: #EDF6F9;
    border-radius: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  .natal-chart-button-area {
    width: 90%;
    border: 0px solid;
    padding: 30px;
    cursor: pointer;
  }

  .house-tarot-image {
    border: 0px solid;
    width: 90%;
    padding: 5px;
    cursor: pointer;
  }

  .house-tarot-content {
    width: 90%;
    padding: 5px;
  }

}

@media screen and (max-width: 1205px) {

  p {
    color: #344E41;
    text-indent: 10px;
    text-align: left;
    padding: 10px;
  }

  h3 {
    margin: 25px 0 0;
  }

  li {
    text-align: left;
    padding: 3px;
    list-style-position: outside;
  }  

  .tarot-results-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;  
    border-radius: 10px;
    padding-top: 10px;
  }
 
  .tarot-results-content {
    border: 1px solid;
    border-color: #a0a0a0;
    align-content: center;
    background: #EDF6F9;
    border-radius: 10px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  .natal-chart-button-area {
    width: 100%;
    border: 0px solid;
    padding: 30px;
    cursor: pointer;
  }

  .house-tarot-image {
    border: 0px solid;
    width: 100%;
    padding: 5px;
    cursor: pointer;
  }

  .house-tarot-content {
    width: 100%;
    padding: 5px;
  }

}
</style>