<template>

    <!-- THE TOPNAV BAR -->
    <div class="topnav">

        <table style="width:100%" border="0" cellspacing="0" cellpadding="0">
            <tr>
                <td style="width:25%">
                    <!-- Menu Icon + Dropdown Router Links Area -->
                    <div class="dropdown" @mouseleave="fadeDropDown">

                        <!-- Menu Bar Icon : BS : Mouseover for Menu Display --> 
                        <div class="menuBarIcon" @mouseover="showDropDown">
                            <font-awesome-icon :icon="['fas', 'bars']" size="lg" class="fontAwesomeIcon" />
                        </div>

                        <!-- Router Links -->
                        <div class="dropdown-content" id="dropdownContent">
                            <div v-for="(link, index) in navLinks" :key="index">
                                <router-link :to="link.path">
                                    <div class="nav-link-icon" style="float: left">
                                        <font-awesome-icon :icon="link.fawIcon" size="lg" class="fontAwesomeIcon" />
                                    </div>
                                    <div class="nav-link-text">{{ link.text }}</div>
                                </router-link>
                            </div>            
                        </div>

                    </div>    
                </td>

                <td style="width:50%" align="center">
                    <!-- Current Router Link Text Display for Bigger Screens -->
                    <div class="selectedLinkText">
                        <table cellpadding="0" cellspacing="0" width="100%" border="0">
                            <tr>
                                <td width="100%" align="left">
                                    <template v-if="this.$route.name == 'home'">
                                        &nbsp;
                                    </template>
                                    <template v-else-if="this.$route.name == 'natal-chart'">
                                        <font-awesome-icon :icon="['fas', 'file-lines']" size="lg" class="fontAwesomeIcon" /> &nbsp;
                                        Natal Chart
                                    </template>
                                    <template v-else-if="this.$route.name == 'planetary-positions'">
                                        <font-awesome-icon :icon="['fas', 'earth-americas']" size="lg" class="fontAwesomeIcon" /> &nbsp;
                                        Planetary Positions
                                    </template>
                                    <template v-else-if="this.$route.name == 'transits'">
                                        <font-awesome-icon :icon="['fas', 'arrows-rotate']" size="lg" class="fontAwesomeIcon" /> &nbsp;
                                        Transits
                                    </template>
                                    <template v-else-if="this.$route.name == 'panchanga'">
                                        <font-awesome-icon :icon="['fas', 'calendar-alt']" size="lg" class="fontAwesomeIcon" /> &nbsp;
                                        Panchanga
                                    </template>
                                    <template v-else-if="this.$route.name == 'jamakkol'">
                                        <font-awesome-icon :icon="['far', 'hourglass']" size="lg" class="fontAwesomeIcon" /> &nbsp;
                                        Jamakkol Horary
                                    </template>
                                    <template v-else-if="this.$route.name == 'widgets'">
                                        <font-awesome-icon :icon="['fas', 'link']" size="lg" class="fontAwesomeIcon" /> &nbsp;
                                        Embed in Your Website
                                    </template>
                                    <template v-else-if="this.$route.name == 'faq'">
                                        <font-awesome-icon :icon="['fas', 'circle-question']" size="lg" class="fontAwesomeIcon" /> &nbsp;
                                        FAQ
                                    </template>
                                    <template v-else-if="this.$route.name == 'contact'">
                                        <font-awesome-icon :icon="['fas', 'envelope']" size="lg" class="fontAwesomeIcon" /> &nbsp;
                                        Questions / Comments
                                    </template>
                                    <template v-else>
                                    {{ this.$route.name }}
                                    </template>
                                </td>
                            </tr>
                        </table>
                    </div>
                </td>

                <td style="width:25%">

                    <!-- Logo Image Right Aligned for Bigger Screens -->
                    <div @click="$router.push('home')" style="float: right;">
                        <img class="logo-image-style" src="../assets/logo.png" alt="Vedic Astro Chart"/>
                    </div>

                </td>
            </tr>
        </table>

    </div>    

</template>

<script>

export default {
	props: ['navLinks'],
	data() {
		return {
		}
	},
	created() {
	},
	methods: {
        fadeDropDown() {
            document.getElementById('dropdownContent').style.display = 'none';
        },
        showDropDown() {
            document.getElementById('dropdownContent').style.display = 'block';
        },
	}
}
</script>

<style scoped>

.topnav {
  background-color: #344E41;
  height: 65px;
  width: 100%;
  box-shadow: 2px 2px 2px 2px #A3B18A;
}

.logo-image-style {
	width: 215px;
	height: 61px;    
    cursor: pointer;
    margin-right: 30px;
}

.menuBarIcon {
  background-color: #344E41;
  color: white;
  padding: 22px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.selectedLinkText {
    margin-left: 65px;
    padding: 22px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    width: 300px;
}

.dropdown {
  position: absolute;
  top: 1px;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 64px;
  background-color: #ACD8AA;
  box-shadow: 16px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;  
}

.dropdown-content a {
  border: 0px;
  color: #344E41;
  font-size: 18px;
  padding: 10px;
  text-decoration: none;
  display: block;
  height: 18px;  
  width: 250px;
}

.dropdown-content a:hover {background-color: #FFFF66;}

.router-link-exact-active {
	font-weight: bold;
	background: #FFFF66;
}

.nav-link-icon {
    padding-left: 2px;
    padding-right: 12px;
    align-content: center;
    width: 20px;
}

.nav-link-text {
    padding-left: 2px;
    float: left;
}

</style>