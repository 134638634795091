<template>

	<div class="preHeader">
		<h3 style="color:#FBFBFF">
			Jamakkol stands as a distinct manifestation within the realm of predictive arts, rooted in the rich tapestry of Vedic astrology. 
			This specialized method, akin to prasanam or horary astrology, possesses a singular focus: to unveil the mysteries of specific queries through the prism of time.		
		</h3>
	</div>

	<div class="screen-wrapper">

		<div class="jamakkol-intro-wrapper" v-if="firstVisit">
			<div class="jamakkol-intro">
					<u>
						<h3>Jamakkol Horary</h3>
					</u>

					<p style="text-align: justify;">
						<b>Jamakkol</b> is a unique <b>Horary</b> method within <b>Vedic astrology</b>, offering profound clarity and guidance for answering life's myriad questions. 
					</p>

					<p style="text-align: justify;">
						This specialized approach, deeply embedded in the rich tradition of Vedic astrology, is specifically designed to address and illuminate individual life queries.
					</p>				

					<p style="text-align: justify;">
						Jamakkol practitioners skillfully combine the timeless wisdom of Vedic astrology with the mystical influences of the <b>Jamakkol planets</b> to provide precise and insightful predictions.
					</p>
			</div>		

			<div class="link-button-area">
				<a @click="viewJamakkolChart" class="link-button">
					View Jamakkol Chart
				</a>
			</div>
		</div>

		<div class="desktop-jamakkol-positions" v-if="deviceType=='BS' && !firstVisit">
			<Jamakkol />
		</div>

		<div class="mobile-jamakkol-positions" v-if="deviceType=='SS' && !firstVisit">
			<MobileJamakkol />
		</div>
	</div>


</template>

<script>
import Jamakkol from '@/components/Jamakkol'
import MobileJamakkol from '@/components/MobileJamakkol'

export default {
	components: { Jamakkol, MobileJamakkol },
	data() {
		return {
			deviceType: (window.innerWidth > 1205 ? 'BS' : 'SS'),
			firstVisit: localStorage.getItem('jkloc') ? false : true
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
	},	
	beforeUnmount() { 
		window.removeEventListener('resize', this.onResize); 
	},

	methods: {  
		onResize() {
			this.deviceType = (window.innerWidth > 1205 ? 'BS' : 'SS')
		},
		viewJamakkolChart() {
			this.firstVisit = false
		}
	}
  }
</script>

<style scoped>

.preHeader {
	display:none !important;
	visibility: hidden !important;
	color: #FBFBFF !important;
	text-align: center;
}      

.screen-wrapper {
  border: 0px solid;
  display: block;
  clear: both;
  align-content: center;
  margin: 0;
  width: 100%;
  height: 95%;
  display:flex;
  justify-content:center; 
}

.desktop-jamakkol-positions {
	border: 0px solid;
	width:100%;
	height: 95%;
	margin-top:0px;
}

.mobile-jamakkol-positions {
	border: 0px solid;
	width:100%;
	height: 95%;
	margin-top:0px;
}

.jamakkol-intro-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;  
    border-radius: 10px;
    padding-top: 10px;
  }

.jamakkol-intro {
  border: 1px solid;
  border-color: #a0a0a0;
  align-content: center;
  background: #EDF6F9;
  border-radius: 10px;
  margin-top: 50px;
  width: 45%;
  padding: 15px;
  
}

.link-button {
  border-radius: 5px;
  background-color: #087F8C;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  padding: 12px;
  text-decoration: none;
  cursor: pointer;
}

.link-button-area {
  width: 95%;
  border: 0px solid;
  padding: 30px;
}

@media screen and (max-width: 1205px) {

.jamakkol-intro {
	border: 1px solid;
	border-color: #a0a0a0;
	align-content: center;
	background: #EDF6F9;
	border-radius: 10px;

	padding: 10px;
	margin-top: 10%;
	width: 90%;
}

}

</style>
