import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)
app.use(router).mount('#app')

// Vue Version
// console.log('Vue v' + app.version )

// Font Awesome 6 Icons + Vue 3.x
// Setup Instructions
// npm i --save @fortawesome/fontawesome-svg-core
// npm i --save @fortawesome/free-solid-svg-icons
// npm i --save @fortawesome/free-regular-svg-icons
// npm i --save @fortawesome/free-brands-svg-icons
// npm i --save @fortawesome/vue-fontawesome@prerelease (Vue 3.x)

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarDays, faLocationDot, faMapLocation, faExclamationTriangle, 
         faFileLines, faEarthAmericas, faSpinner, faLink, faCircleQuestion,
         faEnvelope, faGear, faSquarePlus, faSquareMinus, faArrowsSpin,
         faMapLocationDot, faArrowsRotate, faFilePdf, faDiamond, faSquare, 
         faFloppyDisk, faLocationArrow, faBars} from '@fortawesome/free-solid-svg-icons'
import { faUser, faClock, faCalendarAlt, faHourglass } from '@fortawesome/free-regular-svg-icons'
import { faBlogger } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faCalendarDays, faUser, faClock, faLocationDot, faMapLocation, 
            faExclamationTriangle, faFileLines, faEarthAmericas, faSpinner, 
            faCalendarAlt, faLink, faCircleQuestion, faEnvelope, faBlogger,
            faGear, faSquarePlus, faSquareMinus, faArrowsSpin, faMapLocationDot,
            faArrowsRotate, faFilePdf, faHourglass, faDiamond, faSquare, 
            faFloppyDisk, faLocationArrow, faBars)

app.component('font-awesome-icon', FontAwesomeIcon)
app.config.productionTip = false

// Client side error logging
import HTTP from "./http-axios";
app.config.errorHandler = (err, instance, info) => {
    //console.log(err);
    // handle error: report to server side controller for logging
    HTTP().post(`/api/error/vuejs?err=` + JSON.stringify(err))
}
