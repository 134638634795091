<template>

    <!-- THE TOPNAV BAR -->
    <div class="topnav">

        <table style="width:100%" border="0" cellspacing="0" cellpadding="0">
            <tr>
                <td style="width:20%">
                    <!-- Menu Icon + Dropdown Router Links Area -->
                    <div class="dropdown" @mouseleave="fadeDropDown">

                        <!-- Menu Bar Icon : SS : Click and Toggle Menu Display --> 
                        <div class="menuBarIcon" @click="toggleDropDown()">
                            <font-awesome-icon :icon="['fas', 'bars']" size="lg" class="fontAwesomeIcon" />
                        </div>

                        <!-- Router Links -->
                        <div class="dropdown-content" id="dropdownContent">
                            <div v-for="(link, index) in navLinks" :key="index">
                                <router-link :to="link.path">
                                    <div class="nav-link-icon" style="float: left">
                                        <font-awesome-icon :icon="link.fawIcon" size="lg" class="fontAwesomeIcon" />
                                    </div>
                                    <div class="nav-link-text">{{ link.text }}</div>
                                </router-link>
                            </div>            
                        </div>

                    </div>    

                </td>

                <td align="right">

                    <!-- Logo Image Center Aligned for Smaller Screens -->
                    <div @click="$router.push('home')" >
                        <img class="logo-image-style" src="../assets/logo.png" alt="Vedic Astro Chart"/>
                    </div>

                </td>

            </tr>
        </table>




    </div>    

</template>

<script>

export default {
	props: ['navLinks'],
	data() {
		return {
		}
	},
	created() {
	},
	methods: {
        fadeDropDown() {
            document.getElementById('dropdownContent').style.display = 'none';
        },
        toggleDropDown() {

            if ( document.getElementById('dropdownContent').style.display == 'block') {
                document.getElementById('dropdownContent').style.display = 'none';
            } else {
                document.getElementById('dropdownContent').style.display = 'block';
            }
        },
	}
}
</script>

<style scoped>

.topnav {
  background-color: #344E41;
  height: 65px;
  width: 100%;
  box-shadow: 2px 2px 2px 2px #A3B18A;
}

.logo-image-style {
	width: 215px;
	height: 61px;    
    cursor: pointer;
    margin-right: 20px;
}

.menuBarIcon {
  background-color: #344E41;
  color: white;
  padding: 22px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  top: 1px;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 64px;
  background-color: #ACD8AA;
  box-shadow: 16px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;  
}

.dropdown-content a {
  border: 0px;
  color: #344E41;
  font-size: 18px;
  padding: 10px;
  text-decoration: none;
  display: block;
  height: 18px;  
  width: 250px;
}

.dropdown-content a:hover {background-color: #FFFF66;}

.router-link-exact-active {
	font-weight: bold;
	background: #FFFF66;
}

.nav-link-icon {
    padding-left: 2px;
    padding-right: 12px;
    align-content: center;
    width: 20px;
}

.nav-link-text {
    padding-left: 2px;
    float: left;
}

</style>